<template>
  <div class="d-flex flex-column gap-7 gap-lg-10" v-loading="fullscreenLoading">
    <div class="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
      <!--begin:::Tabs-->
      <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-lg-n2 me-auto">
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_summary">Order Summary</a>
        </li>
        <!--end:::Tab item-->
        <!--begin:::Tab item-->
        <li class="nav-item" v-if="permission.isReadOrderHistoryAllowed">
          <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history">Order History</a>
        </li>
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->
    </div>

    <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10" >
      <!--begin::Order details-->
      <div class="card card-flush py-4 flex-row-fluid" >
        <div class="card-header">
          <div class="card-title">
            <h2>{{ordersConstants.ORDER_DETAILS}} (#{{orderId}})</h2>
          </div>
        </div>
        <div class="card-body pt-0" v-if="orderDetails.value">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.order_status">
                  <td class="text-muted">
                    <div class="d-flex align-items-center"><i class="fas fa-truck me-2 fs-2 d-none"></i>{{ordersConstants.ORDER_STATUS}}</div>
                  </td>
                  <td class="fw-bolder text-end">
                    <span v-if="orderDetails.value.orderstatus != null" :class="`badge badge-light-${ orderDetails.value.orderstatus.badge }`">{{ orderDetails.value.orderstatus.title }}</span>
                    <span v-else> {{ '-' }}</span>
                  </td>
                </tr>
                <tr v-if="!!orderDetails.value.order_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatDateTime(orderDetails.value.order_date) }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.order_due_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_DUE_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatDate(orderDetails.value.order_due_date)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.order_due_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_STATUS}}</div>
                  </td>
                  <td class="fw-bolder text-end"><span class="badge badge-light-success">{{orderDetails.value.payment_details.payment_status}}</span></td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.total_amount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_AMOUNT}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatPrice(orderDetails.value.payment_details.total_amount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Order details-->
      <!--begin::Customer details-->
      <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value">
        <div class="card-header">
          <div class="card-title">
            <h2>{{ordersConstants.CUSTOMER_DETAILS}}</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.customer_name">
                  <td class="text-muted">
                    <div class="d-flex align-items-center"><i class="fas fa-truck me-2 fs-2 d-none"></i>{{ordersConstants.CUSTOMER}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ orderDetails.value.customer_name }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_email">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.EMAIL}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_email}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_phone">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PHONE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_phone}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_company">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.COMPANY}}:</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_company}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Customer details-->
      <!--begin::Payment & Shipping-->
      <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value && (orderDetails.value.payment_details || orderDetails.value.shipping_type)">
        <div class="card-header">
          <div class="card-title">
              <h2>{{ordersConstants.PAYMENT_AND_SHIPPING}}</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.payment_details.payment_method">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_METHOD}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.payment_details.payment_method}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.payment_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatDate(orderDetails.value.payment_details.payment_date) }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.transaction_id">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.TRANSACTION_ID}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.payment_details.transaction_id}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.discount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Discount</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.discount)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.payment_processing_fees">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Payment Processing Fees</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.payment_processing_fees)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.shipping_charges">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Shipping Charges</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.shipping_charges)}}</td>
                </tr>
                 <tr v-if="!!orderDetails.value.payment_details.blind_shipping_charges">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Blind Shipping Charges</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.blind_shipping_charges)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.tax">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Tax</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.tax)}}</td>
                </tr>
                 <tr v-if="!!orderDetails.value.payment_details.subtotal">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Subtotal</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.subtotal)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.total_amount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Total Amount</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.total_amount)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.shipping_method">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.SHIPPING_METHOD}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.shipping_method}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.shipping_type">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.SHIPPING_TYPE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.shipping_type}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Payment & Shipping-->

    <!--begin::Tab content-->
    <div class="tab-content">
      <!--begin::Tab pane-->
      <div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
        <div class="d-flex flex-column gap-7 gap-lg-10" v-if="orderDetails.value">
          <!--begin::Address Block-->
          <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value.billing_address">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm001.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.BILLING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0">
                <span v-if="orderDetails.value.billing_address.name">{{orderDetails.value.billing_address.name}}<br></span>
                <span v-if="orderDetails.value.billing_address.address1">{{orderDetails.value.billing_address.address1}}<br></span>
                <span v-if="orderDetails.value.billing_address.address2">{{orderDetails.value.billing_address.address2}}, </span>{{orderDetails.value.billing_address.city}}<br>
                <span v-if="orderDetails.value.billing_address.state">{{orderDetails.value.billing_address.state}}<br></span>
                <span v-if="orderDetails.value.billing_address.country">{{orderDetails.value.billing_address.country}} -</span><span v-if="orderDetails.value.billing_address.postcode">{{orderDetails.value.billing_address.postcode}}<br></span>
                <span v-if="!!orderDetails.value.billing_address.phone">{{ordersConstants.PHONE_NUMBER}} : </span> {{orderDetails.value.billing_address.phone}}<br>
                <span v-if="!!orderDetails.value.billing_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.billing_address.company}}
              </div>
            </div>
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value.shipping_address">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm006.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.SHIPPING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0">
                <span v-if="orderDetails.value.shipping_address.name">{{orderDetails.value.shipping_address.name}}<br></span>
                <span v-if="orderDetails.value.shipping_address.address1">{{orderDetails.value.shipping_address.address1}}<br></span>
                <span v-if="orderDetails.value.shipping_address.address2">{{orderDetails.value.shipping_address.address2}}, </span>{{orderDetails.value.shipping_address.city}}<br>
                <span v-if="orderDetails.value.shipping_address.state">{{orderDetails.value.shipping_address.state}}<br></span>
                <span v-if="orderDetails.value.shipping_address.country">{{orderDetails.value.shipping_address.country}} -</span><span v-if="orderDetails.value.shipping_address.postcode"> {{orderDetails.value.shipping_address.postcode}}<br></span>
                <span v-if="!!orderDetails.value.shipping_address.phone">{{ordersConstants.PHONE_NUMBER}} : </span> {{orderDetails.value.shipping_address.phone}}<br>
                <span v-if="!!orderDetails.value.shipping_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.shipping_address.company}}
              </div>
            </div>

            <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value.blind_shipping == 1">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm006.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.BLIND_SHIPPING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0">
                <span>{{orderDetails.value.blind_shipping_address.name}}</span><br>
                <span>{{orderDetails.value.blind_shipping_address.address1}}</span><br>
                <span>{{orderDetails.value.blind_shipping_address.address2}}, </span>{{orderDetails.value.blind_shipping_address.city}}<br>
                <span>{{orderDetails.value.blind_shipping_address.state}}</span><br>
                <span>{{orderDetails.value.blind_shipping_address.country}} - {{orderDetails.value.blind_shipping_address.postcode}}</span><br>
                <span v-if="!!orderDetails.value.blind_shipping_address.phone">{{ordersConstants.PHONE_NUMBER}} : </span> {{orderDetails.value.blind_shipping_address.phone}}<br>
                <span v-if="!!orderDetails.value.blind_shipping_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.blind_shipping_address.company}}
              </div>
            </div>
          </div>
          <!--end::Address Block-->
        
          <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value">
            <!-- <div class="card-header">
              <div class="card-title">
                <h2> {{ordersConstants.ORDER_ITEMS}}  ({{orderDetails.value.store_order_id}})</h2>
              </div>
            </div> -->
            <div class="card-body pt-0">
              <div class="row mb-3">
                <div class="d-flex flex-column  rounded border p-5">
                  <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5  justify-content-start">
                    <li class="nav-item fw-bold fs-6">
                      <a
                        class="nav-link text-active-primary active"
                        data-bs-toggle="tab"
                        href="#orderItems"
                      > {{ordersConstants.ORDER_ITEMS}} </a>
                    </li>
                      <li class="nav-item fw-bold fs-6">
                      <a
                        class="nav-link text-active-primary"
                        data-bs-toggle="tab"
                        href="#shipment"
                      > {{ordersConstants.SHIPMENT}} </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="orderItems"
                      role="tabpanel"
                    >
                      <Datatable
                          :table-data="tableData"
                          :table-header="tableHeader"
                          :empty-table-text="noDataFound"
                          :loading="loading"
                          :enable-items-per-page-dropdown="false"
                        >
                            <template v-slot:cell-image="{ row: data }">
                              <el-image
                                  style="width: 100px; height: 100px"
                                  :src="data.product_image[0]"
                                  :preview-src-list="data.product_image"
                                  :initial-index="0"
                                  :hide-on-click-modal=true
                                  @click="stopScrolling()"
                                  @close="closeImage()"
                                  fit="cover"
                                >
                                <template #error>
                                    <div class="image-slot">
                                        <el-image
                                            style="width: 100px; height: 100px"
                                            :src="'/media/no-img.jpg'"
                                            fit="cover"
                                            />
                                    </div>
                                </template>
                              </el-image>
                            </template>
                            <template v-slot:cell-productDetails="{ row: data }">
                              <div class="d-flex align-items-center">
                                <div >
                                  <a href="javascript:void(0)" @click.prevent="openDetailPage(orderId, data.product_id)">{{ data.product_name }}</a>
                                  <div class="fs-7 text-muted" v-if="!!data.product_sku"><span class="text-gray-800">{{generalConstants.SKU}}:</span>
                                    <template v-if="!!data.product_sku">{{ data.product_sku }}</template>
                                  </div>
                                  <div class="fs-7 text-muted" v-if="!!data.separated_product_sku"><span class="text-gray-800">{{constants.product.PRODUCT_SKU}}:</span>
                                    <template v-if="!!data.reference_sku_code">
                                      <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? 'Mapped with: ' + data.reference_sku_code : data.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" ><span :class="data.sku_status == 'Matched' ? 'badge badge-light-success' : data.sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_product_sku }}</span></el-tooltip>
                                      <el-tooltip class="box-item" effect="dark" :content="'Mapped with: ' + data.reference_sku_code" placement="top" raw-content>
                                        <i class="bi bi-question-circle-fill"></i>
                                      </el-tooltip>
                                    </template>
                                  </div>
                                  <div class="fs-7 text-muted" v-if="!!data.separated_art_sku"><span class="text-gray-800">{{constants.product.ART_SKU}}:</span>
                                    <template v-if="!!data.reference_art_sku_code">
                                      <el-tooltip class="box-item" effect="dark" :content="data.art_sku_status == 'Matched' ? 'Mapped with: ' + data.reference_art_sku_code : data.art_sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" ><span :class="data.sku_status == 'Matched' ? 'badge badge-light-success' : data.art_sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_art_sku }}</span></el-tooltip>
                                      <el-tooltip class="box-item" effect="dark" :content="'Mapped with: ' + data.reference_art_sku_code" placement="top" raw-content>
                                        <i class="bi bi-question-circle-fill"></i>
                                      </el-tooltip>
                                    </template>
                                  </div>
                                  <div class="fs-7 text-muted" v-if="!!data.product_weight">{{ordersConstants.WEIGHT}}: {{ data.product_weight }}</div>
                                </div>
                              </div>
                            </template>
                            <template v-slot:cell-additionalInfo="{ row: data }">
                              <div v-if="data.product_additional_info.length > 0">
                                <div
                                  v-for="(optionsData, i) in data.product_additional_info"
                                  :key="i"
                                >
                                  <dl class="row mb-0">
                                    <dt class="col-sm-6 text-right pl-0 mb-0 pr-0">
                                      <span v-html="optionsData.option_title"></span> :
                                    </dt>
                                    <dd class="col-sm-6 mb-0">
                                      <span v-html="optionsData.option_value"></span>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                              <div v-else>
                                -
                              </div>
                            </template>
                            <template v-slot:cell-shippingTypeAndMethods="{ row: data }">
                              <span v-if="orderDetails.value.shipping_method || orderDetails.value.shipping_type">
                              <span>{{ orderDetails.value.shipping_method }} </span><br><span>{{ orderDetails.value.shipping_type }}</span>
                              </span>
                              <span v-else>-</span>
                            </template>
                            <template v-slot:cell-status="{ row: data }">
                              <span :class="'badge badge-light-' + data.order_product_status_badge">{{data.order_product_status_title}}</span>
                            </template>
                            <template v-slot:cell-quantity="{ row: data }">
                              <span class="badge bg-primary">{{ data.product_quantity }}</span>
                            </template>
                            <template v-slot:cell-price="{ row: data }">
                              {{ formatPrice(data.product_price) }}
                            </template>
                            <template v-slot:cell-actions="{ row: data }">
                              <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="Update Order Product Status"
                                placement="top"
                              >
                                <button type="button" @click="manageOrderProductstatus(data.product_id, data.order_product_status_id)"  data-bs-toggle="modal" data-bs-target="#updateOrderProductStatusModal" class="btn btn-icon btn-light btn-sm me-2">
                                  <i class="bi bi-pencil"></i>
                                </button>
                              </el-tooltip>
                              <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="Download Files"
                                placement="top"
                              >
                                <button v-if="data.is_files_downloaded == 0" type="button" @click="downloadFiles(orderId, data.product_id, tableData.findIndex((i) => i.product_id === data.product_id))" class="btn btn-icon btn-light btn-sm me-2">
                                  <i class="bi bi-file-earmark-arrow-down"></i>
                                </button>
                              </el-tooltip>
                              <!-- <el-tooltip
                                class="box-item"
                                effect="dark"
                                :content="ordersConstants.MODIFY_DOWNLOAD_SHIPMENT_LABEL"
                                placement="top"
                                v-if="data.order_product_status_type != 'shipped'"
                              >
                                <span v-if="data.product_quantity > 0">
                                  <button v-if="permission.isDownloadShipmentLabelAllowed" type="button" data-bs-toggle="modal" data-bs-target="#shipment_modal" @click.prevent="manageShipmentLabel(data)" class="btn btn-icon btn-light btn-sm me-2">
                                    <i class="bi bi-truck"></i>
                                  </button>
                                </span>
                                <span v-else>
                                  <button v-if="permission.isDownloadShipmentLabelAllowed" type="button" @click.prevent="manageShipmentLabel(data)" class="btn btn-icon btn-light btn-sm me-2">
                                    <i class="bi bi-truck"></i>
                                  </button>
                                </span>
                              </el-tooltip> -->
                              <!-- <el-tooltip
                                class="box-item"
                                effect="dark"
                                :content="ordersConstants.DOWNLOAD_SHIPMENT_LABEL"
                                placement="top"
                              >
                                <button v-if="permission.isDownloadShipmentLabelAllowed" type="button" @click.prevent="manageShipmentLabel(data, true)" class="btn btn-icon btn-light btn-sm me-2">
                                  <i class="bi bi-download"></i>
                                </button>
                              </el-tooltip> -->
                            </template>
                          </Datatable>
                    </div>
                      <!-- Shipment :: Start -->
                    <div
                      class="tab-pane fade"
                      id="shipment"
                      role="tabpanel"
                    >
                      <!-- Shipment Listing :: Start -->
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer " v-if="orderShipmentData.length > 0">
                        <thead>
                          <tr class="fw-bolder fs-7 text-gray-800">
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ generalConstants.SRNO }}</th>
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.SHIPPING_METHOD }}</th>
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.SHIPPING_TYPE }}</th>
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.PRODUCT_ITEM }}</th>
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.TRACKING_NO }}</th>
                            <th class="text-gray-800 fw-bold fs-7 text-uppercase gs-0">{{ generalConstants.ACTION }}</th>
                          </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                          <template v-if="orderShipmentData.length > 0">
                          <template v-for="(shipment, index) in orderShipmentData" :key="index" >
                          <tr v-bind:id="'row_'+index" >
                            <td>{{ index + 1 }}</td>
                            <td>{{shipment.shipping_method.shipping_method}}
                                <i 
                                    :id="'prodctsUpDown' + shipment.order_shipment_id"
                                    :title="ordersConstants.VIEWORDERPRODUCTS"
                                    class="bi bi-caret-down-square fs-5 text-primary cursor-pointer"
                                    @click.prevent="showHideProducts(shipment.order_shipment_id)"
                                  ></i>
                            </td>
                            <td>{{shipment.shipping_type.shipping_type}}</td>
                            <td>
                              <!-- order products name -->
                              <template v-if="shipment.type == 2 ">
                                  <div v-for="(product, i) in shipment.order_products" :key="'product' + i">
                                    <span :title="product.product_name"  class="badge-success badge me-2 mb-2">{{product.product_name}}</span>
                                  </div>
                              </template>
                              <template v-else>
                                  <!-- <span :title="ordersConstants.ALL"  class="badge-success badge me-2 mb-2">{{ ordersConstants.ALL }}</span> -->
                                  <template v-for="(product, key) in tableData" :key="key">
                                    <label class="text-gray-800 fw-bold" data-kt-table-widget-4="template_name">{{key+1}}. {{ product.product_name }}</label><br v-if="tableData.length != 0">
                                  </template>
                              </template>
                            </td>
                            <td>
                              <template v-if="shipment.tracking_no != null ">
                                {{shipment.tracking_no}}
                              </template>
                              <template v-else>
                                  <span :title="ordersConstants.FAILED"  class="badge-danger badge me-2 mb-2">{{ ordersConstants.FAILED }}</span>
                              </template>
                            </td>
                            <td>
                              <div>
                                <a href="javascript:void(0);" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :id="'menuAction' + shipment.order_shipment_id" >{{ generalConstants.ACTIONS}}
                                  <span class="svg-icon svg-icon-5 m-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                                    </svg>
                                  </span>
                                </a>
                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4" data-kt-menu="true">
                                  <div class="menu-item px-3" v-if="shipment.tracking_no != null">
                                    <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="downloadShipmentLabel(shipment.order_shipment_id)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.DOWNLOAD_SHIPMENT_LABEL}}</a>
                                  </div>
                                  <div class="menu-item px-3" v-if="shipment.tracking_no == null && isRegenerateShipmentLabel">
                                    <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="reGenerateShippingLabel(shipment.order_shipment_id)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.REGENERATE_SHIPPING_LABEL }}</a>
                                  </div>
                                  <div class="menu-item px-3" v-if="shipment.tracking_no == null">
                                    <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="downloadShipmentLabel(shipment.order_shipment_id)"><i class="bi bi-eye"></i> &nbsp; {{ ordersConstants.VIEW_ERROR_FILE }}</a>
                                  </div>
                                  <div class="menu-item px-3">
                                    <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="deleteOrderShipment(shipment.order_shipment_id)" :title="generalConstants.DELETE"><i class="bi bi-trash-fill fs-5"></i> &nbsp; {{ generalConstants.DELETE }}</a>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr :id="'orderProductsTr' + orderShipmentData[index].order_shipment_id" class="d-none orderProductsTrAll">
                              <td colspan="5" class="ps-15">
                              <table class="table table-row-dashed fs-6 gy-5 dataTable no-footer border" style="background-color: #f0f4f7">
                                <thead>
                                  <tr class="fw-bolder fs-5 text-gray-800">
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 ps-3 border">{{ ordersConstants.WEIGHT_UNIT }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.WEIGHT }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.QUANTITY }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.LENGTH_UNIT }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.LENGTH }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.HEIGHT }}</th>
                                    <th class="fw-bold fs-7 py-2 bg-light text-uppercase gs-0 border">{{ ordersConstants.WIDTH }}</th>
                                  </tr>
                                </thead>
                                <tbody class="text-gray-600">
                                  <tr v-for="(packageRow, k) in orderShipmentData[index].order_shipment_packages" :key="k" v-bind:id="'row_'+k">
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.weight_class}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.weight}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.quantity}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.length_class}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.package_length}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.package_height}}</td>
                                      <td class="py-2 ps-3 bg-white border" style="vertical-align: middle;">{{packageRow.package_width}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              </td>
                          </tr>
                          </template>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="align-center" colspan="5">
                                <p style="text-align: center">{{ generalConstants.NO_DATA_FOUND }}</p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <!-- Shipment Listing :: End -->
                      <div class="separator border-2 my-10" v-if="orderShipmentData.length > 0 && isGenerateShippingLabel"></div>
                      <!-- Create Shipment Form :: Start -->
                        <div class="accordion" id="kt_accordion_1" v-if="isGenerateShippingLabel">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="kt_accordion_1_header_2">
                              <button
                                  class="color-dark accordion-button fs-5 fw-bold collapsed bg-light-primary "
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#kt_accordion_1_body_2"
                                  aria-expanded="false"
                                  aria-controls="kt_accordion_1_body_2"
                              >
                                  {{ordersConstants.CREATE_SHIPMENT}}
                              </button>
                            </h2>
                            <div
                              id="kt_accordion_1_body_2"
                              class="accordion-collapse collapse"
                              :class="orderShipmentData.length > 0 ? '': 'show'"
                              aria-labelledby="kt_accordion_1_header_2"
                              data-bs-parent="#kt_accordion_1"
                            >
                              <div class="accordion-body">
                                <div class="align-items-center position-relative my-1">
                                    <div class="row">
                                      <el-form :model="formData" :rules="rules" ref="formRef" class="w-100">
                                        <div class="row">
                                          <!--From Address::start-->
                                          <div class="col-md-4">
                                            <label class="me-2">{{ordersConstants.FROM_ADDRESS}}</label>
                                            <button
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#update_address"
                                            :title="generalConstants.EDIT"
                                            @click="showModal('from')"
                                            class="btn btn-icon btn-active-light-success w-30px h-30px me-3"
                                            ><i class="bi bi-pencil-fill fs-6"></i>
                                          </button>
                                          <button
                                            v-if="returnAddresses && returnAddresses.length !== 0"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#select_address"
                                            :title="ordersConstants.ADDRESSES"
                                            @click="showModal('from')"
                                            class="btn btn-icon btn-active-light-success w-30px h-30px me-3"
                                            ><i class="bi bi-map-fill fs-6"></i>
                                          </button>
                                            <div class="pb-5">
                                                <div class="fw-bold text-gray-600">{{fromAddress.name}}</div>
                                                <div class="text-muted">
                                                  <span v-if="fromAddress && fromAddress.address1">{{fromAddress.address1}}<br></span>
                                                  <span v-if="fromAddress && fromAddress.address2">{{fromAddress.address2}}<br></span>
                                                  <span v-if="fromAddress && fromAddress.city">{{fromAddress.city}}<br></span>
                                                  <span v-if="fromAddress && fromAddress.state">{{fromAddress.state}} <span v-if="fromAddress && fromAddress.state_code">({{fromAddress.state_code}})</span> <span v-if="fromAddress && fromAddress.postal_code"> - {{fromAddress.postal_code}} </span><br></span>
                                                  <span v-if="fromAddress && fromAddress.country">{{fromAddress.country}}<span v-if="fromAddress && fromAddress.country_code"> ({{fromAddress.country_code}})</span>.</span>
                                                  <span v-if="fromAddress && fromAddress.phone_number"><br><span class="fw-bold text-gray-600">{{ ordersConstants.PHONE_NUMBER }}: </span>{{fromAddress.phone_number}}</span>
                                                  <span v-if="fromAddress && fromAddress.company"><br><span class="fw-bold text-gray-600">{{ ordersConstants.COMPANY }}: </span>{{fromAddress.company}}</span>
                                                </div>
                                            </div>
                                          </div>
                                          <!--From Address::end-->
                                          <!--To Address::start-->
                                          <div class="col-md-4">
                                            <label class="me-2">{{ordersConstants.TO_ADDRESS}}</label>
                                            <button
                                              type="button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#update_address"
                                              :title="generalConstants.EDIT"
                                              @click="showModal('to')"
                                              class="btn btn-icon btn-active-light-success w-30px h-30px me-3"
                                              ><i class="bi bi-pencil-fill fs-6"></i>
                                            </button>
                                            <div class="pb-5">
                                                <div class="fw-bold text-gray-600">{{orderDetails.value.shipping_address.name}}</div>
                                                <div class="text-muted">
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.address1">{{orderDetails.value.shipping_address.address1}}<br></span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.address2">{{orderDetails.value.shipping_address.address2}}<br></span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.city">{{orderDetails.value.shipping_address.city}}<br></span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.state">{{orderDetails.value.shipping_address.state}} <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.state_code">({{orderDetails.value.shipping_address.state_code}})</span> <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.postcode"> - {{orderDetails.value.shipping_address.postcode}} </span><br></span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.country">{{orderDetails.value.shipping_address.country}}<span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.country_code"> ({{orderDetails.value.shipping_address.country_code}})</span>.</span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.phone"><br><span class="fw-bold text-gray-600">{{ordersConstants.PHONE_NUMBER}}: </span>{{orderDetails.value.shipping_address.phone}}</span>
                                                  <span v-if="orderDetails.value.shipping_address && orderDetails.value.shipping_address.company"><br><span class="fw-bold text-gray-600">{{ordersConstants.COMPANY}}: </span>{{orderDetails.value.shipping_address.company}}</span>
                                                </div>
                                            </div>
                                            <label id="addressAlert" :class="'text-' + addressValidationClass" v-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'primary'">
                                                {{addressValidationMessage}} 
                                                <span class="spinner-border text-primary fs-5"></span>
                                            </label>
                                            <el-tooltip
                                              class="box-item"
                                              effect="dark"
                                              :content="addressValidationResponse"
                                              placement="top"
                                              v-if="addressValidationResponse != null && addressValidationResponse != '' && addressValidationClass == 'danger'"
                                            >  
                                              <label id="addressAlert" :class="'text-' + addressValidationClass" v-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'danger'" >
                                                {{addressValidationMessage}} 
                                                <i :class="'bi bi-info-circle fs-6 text-' + addressValidationClass"></i>
                                              </label>
                                            </el-tooltip>
                                              <label id="addressAlert" :class="'text-' + addressValidationClass" v-else-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'danger'" >
                                                {{addressValidationMessage}} 
                                              </label>
                                            <label id="addressAlert" :class="'text-' + addressValidationClass" v-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'success'">
                                                {{addressValidationMessage}} 
                                            </label>
                                            <el-tooltip
                                              class="box-item"
                                              effect="dark"
                                              :content="ordersConstants.CLICK_SUGGESTED_ADDRESSES"
                                              placement="top"
                                              v-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'warning'"
                                            >
                                              <label id="addressAlert" :class="'text-' + addressValidationClass" v-if="addressValidationMessage != null && addressValidationMessage != '' && addressValidationClass == 'warning'" data-bs-toggle="modal" data-bs-target="#suggested_address">
                                                {{addressValidationMessage}}
                                                <i :class="'bi bi-exclamation-triangle-fill fs-6 text-' + addressValidationClass"></i>
                                              </label>
                                            </el-tooltip>
                                          </div>
                                          <!--To Address::end-->
                                        </div>
                                        <div class="row mt-3" v-if="!isDisabledOrderShipment">
                                          <div class="col-md-6">
                                            <label class="required fs-6 fw-semibold">{{ordersConstants.SHIPMENT_TYPE}}</label>
                                            <br />
                                            <el-form-item prop="shipment_type" class="mb-3">
                                              <el-radio-group v-model="orderShipmentType">
                                                <el-radio
                                                  v-for="(item, i) in shipmentType"
                                                  :key="i"
                                                  :label="item.value"
                                                  :value="item.value"
                                                  >{{item.text}}</el-radio
                                                >
                                              </el-radio-group>
                                            </el-form-item>
                                          </div>
                                        </div>
                                        
                                        <div class="separator border-2 my-5"></div>
                                        <template v-if="orderShipmentType == 1" v-loading="loading">
                                          <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack">
                                            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                              <h1 class="page-heading d-flex text-dark fw-semibold fs-3 flex-column justify-content-center my-0">{{ ordersConstants.PACKAGE_CONFIGURATION }}</h1>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                              <button class="btn btn-primary mb-2 float-end" @click.prevent="addOrderPackage"><i class="bi bi-plus-circle-fill fs-4"></i>{{ generalConstants.ADD }}</button>
                                            </div>
                                          </div>
                                          <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" >
                                              <thead>
                                                <tr class="fw-bolder fs-5 text-gray-800">
                                                  <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{  ordersConstants.MASTER_PACKAGE }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WEIGHT_UNIT }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WEIGHT }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.QUANTITY }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.LENGTH_UNIT }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.LENGTH }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.HEIGHT }}</th>
                                                  <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WIDTH }}</th>
                                                  <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ generalConstants.ACTION }}</th>
                                                </tr>
                                              </thead>
                                            
                                              <tbody class="text-gray-600">
                                                <template v-if="formData.orderPackages.length > 0">
                                                  <tr v-for="(shipmentPackage, index) in formData.orderPackages" :key="index" v-bind:id="'row_'+index" align="center">
                                                    <td>
                                                      <el-select
                                                        v-model="shipmentPackage.master_package_id"
                                                        size="large"
                                                        class="w-100"
                                                        filterable
                                                        :placeholder="ordersConstants.MASTER_PACKAGE"
                                                        clearable
                                                        @change="getMasterPackageRowDataForOrder($event, index)"
                                                      >
                                                        <el-option
                                                          v-for="packageRow in masterPackages"
                                                          :key="packageRow.master_package_id"
                                                          :label="packageRow.package_name"
                                                          :value="packageRow.master_package_id"
                                                        />
                                                      </el-select>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.weight_unit'"  class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.WEIGHT_UNIT_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-select
                                                          size="large"
                                                          v-model="shipmentPackage.weight_unit"
                                                          class="w-100"
                                                          :placeholder="ordersConstants.WEIGHT_UNIT"
                                                        >
                                                          <el-option 
                                                            v-for="(data, i) in weightUnit"
                                                            :key="'weight_unit_'+i"
                                                            :label="data.text"
                                                            :value="data.value" 
                                                          />
                                                        </el-select>
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.weight'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.WEIGHT_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackage.weight" :placeholder="ordersConstants.WEIGHT" :tabindex="index"/> 
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.quantity'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.QUANTITY_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackage.quantity" :placeholder="ordersConstants.QUANTITY" :tabindex="index"/>
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.dimension_unit'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.LENGTH_UNIT_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-select
                                                          size="large"
                                                          v-model="shipmentPackage.dimension_unit"
                                                          class="w-100"
                                                          :placeholder="ordersConstants.LENGTH_UNIT"
                                                          :tabindex="index"
                                                        >
                                                          <el-option 
                                                            v-for="(data, i) in dimensionUnit"
                                                            :key="'dimension_unit_'+i"
                                                            :label="data.text"
                                                            :value="data.value" 
                                                          />
                                                        </el-select>
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.length'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.LENGTH_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackage.length" :placeholder="ordersConstants.LENGTH" :tabindex="index"/>
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.height'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.HEIGHT_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackage.height" :placeholder="ordersConstants.HEIGHT" :tabindex="index"/>
                                                      </el-form-item>
                                                    </td>
                                                    <td>
                                                      <el-form-item :prop="'orderPackages.' + index + '.width'" class="mb-0" :rules="[
                                                        {
                                                          required: true,
                                                          message: ordersConstants.WIDTH_IS_REQUIRED,
                                                          trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackage.width" :placeholder="ordersConstants.WIDTH" :tabindex="index"/>
                                                      </el-form-item>
                                                    </td>
                                                    <td class="text-center">
                                                      <i @click="deletePackageConfigurationOfOrder(shipmentPackage)" class="bi bi-trash text-danger fs-5 cursor-pointer" name="delete" :id="index"></i>
                                                    </td>
                                                  </tr>
                                                </template>
                                                <template v-else>
                                                  <tr>
                                                    <td class="text-center" colspan="8">{{ generalConstants.NO_DATA_FOUND }}</td>
                                                  </tr>
                                                </template>
                                              </tbody>
                                          </table>
                                        </template>
                                        <template v-if="orderShipmentType == 2 " v-loading="loading">
                                        <div v-for="(product, key) in tableData" :key="key">
                                          <template v-if="formData.productPackages[product.product_id] !== undefined">
                                            <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack" >
                                              <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                                <el-checkbox 
                                                  :label="product.product_name"
                                                  :id="'product_'+product.product_id"
                                                  name="product_checkbox"
                                                  @change="checkOrderProductIsChecked($event,product.product_id)"
                                                >
                                                </el-checkbox>
                                                
                                              </div>
                                              <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                <button class="btn btn-primary mb-2 float-end" @click.prevent="addProductPackage(product.product_id)"><i class="bi bi-plus-circle-fill fs-4"></i>{{ generalConstants.ADD }}</button>
                                              </div>
                                            </div>
                                            <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                                              <thead>
                                                <tr class="fw-bolder fs-5 text-gray-800">
                                                    <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{  ordersConstants.MASTER_PACKAGE }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WEIGHT_UNIT }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WEIGHT }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.QUANTITY }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.LENGTH_UNIT }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.LENGTH }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.HEIGHT }}</th>
                                                    <th class="required text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.WIDTH }}</th>
                                                    <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ generalConstants.ACTION }}</th>
                                                </tr>
                                              </thead>
                                              <tbody class=" text-gray-600">
                                                <template v-if="formData.productPackages[product.product_id] !== undefined && formData.productPackages[product.product_id].length > 0">
                                                  <tr v-for="(shipmentPackageOfPro, index_pro) in formData.productPackages[product.product_id]" :key="index_pro" v-bind:id="'row_'+index_pro" align="center">
                                                    <td>
                                                      <el-select
                                                        v-model="shipmentPackageOfPro.master_package_id"
                                                        size="large"
                                                        class="w-100"
                                                        filterable
                                                        clearable
                                                        :placeholder="ordersConstants.MASTER_PACKAGE"
                                                        @change="getMasterPackageRowDataForOrderProduct($event, product.product_id, index_pro)"
                                                      >
                                                        <el-option
                                                          v-for="packageRow in masterPackages"
                                                          :key="packageRow.master_package_id"
                                                          :label="packageRow.package_name"
                                                          :value="packageRow.master_package_id"
                                                        />
                                                      </el-select>
                                                    </td>
                                                    <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.weight_unit'"  class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.WEIGHT_UNIT_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                          <el-select
                                                              size="large"
                                                              v-model="shipmentPackageOfPro.weight_unit"
                                                              class="w-100"
                                                              :placeholder="ordersConstants.WEIGHT_UNIT"
                                                          >
                                                            <el-option 
                                                            v-for="(data, i) in weightUnit"
                                                            :key="'weight_unit_'+i"
                                                            :label="data.text"
                                                            :value="data.value" 
                                                            />
                                                          </el-select>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-select size="large" v-model="shipmentPackageOfPro.weight_unit" class="w-100" :placeholder="ordersConstants.WEIGHT_UNIT"
                                                          >
                                                            <el-option 
                                                                v-for="(data, i) in weightUnit"
                                                                :key="'weight_unit_'+i"
                                                                :label="data.text"
                                                                :value="data.value" 
                                                            />
                                                          </el-select>
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.weight'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.WEIGHT_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.weight" :placeholder="ordersConstants.WEIGHT" :tabindex="index_pro"/> 
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.weight" :placeholder="ordersConstants.WEIGHT" :tabindex="index_pro"/> 
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.quantity'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            trigger: 'change',
                                                            validator: checkProductQuantity
                                                        }]">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.quantity" :placeholder="ordersConstants.QUANTITY" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.quantity" :placeholder="ordersConstants.QUANTITY" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.dimension_unit'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.LENGTH_UNIT_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                          <el-select
                                                              size="large"
                                                              v-model="shipmentPackageOfPro.dimension_unit"
                                                              class="w-100"
                                                              :placeholder="ordersConstants.LENGTH_UNIT"
                                                          >
                                                              <el-option 
                                                              v-for="(data, i) in dimensionUnit"
                                                              :key="'dimension_unit_'+i"
                                                              :label="data.text"
                                                              :value="data.value" 
                                                              />
                                                          </el-select>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-select
                                                          size="large"
                                                          v-model="shipmentPackageOfPro.dimension_unit"
                                                          class="w-100"
                                                          :placeholder="ordersConstants.LENGTH_UNIT"
                                                          >
                                                            <el-option 
                                                                v-for="(data, i) in dimensionUnit"
                                                                :key="'dimension_unit_'+i"
                                                                :label="data.text"
                                                                :value="data.value" 
                                                            />
                                                          </el-select>
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.length'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.LENGTH_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.length" :placeholder="ordersConstants.LENGTH" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.length" :placeholder="ordersConstants.LENGTH" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.height'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.HEIGHT_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                        <el-input size="large" v-model="shipmentPackageOfPro.height" :placeholder="ordersConstants.HEIGHT" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                            <el-input size="large" v-model="shipmentPackageOfPro.height" :placeholder="ordersConstants.HEIGHT" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                      </td>
                                                      <td>
                                                        <el-form-item v-if="shipmentPackageOfPro.validation_check" :prop="'productPackages.' + product.product_id + '.' + index_pro + '.width'" class="mb-0" :rules="[
                                                        {
                                                            required: true,
                                                            message: ordersConstants.WIDTH_IS_REQUIRED,
                                                            trigger: 'change',
                                                        }]">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.width" :placeholder="ordersConstants.WIDTH" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                        <el-form-item v-else class="mb-0">
                                                          <el-input size="large" v-model="shipmentPackageOfPro.width" :placeholder="ordersConstants.WIDTH" :tabindex="index_pro"/>
                                                        </el-form-item>
                                                      </td>
                                                      <td class="text-center">
                                                        <i @click="deletePackageConfigurationOfOrderProduct(product.product_id, shipmentPackageOfPro)" class="bi bi-trash text-danger fs-5 cursor-pointer" name="delete" :id="index_pro"></i>
                                                      </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                    <td class="text-center" colspan="9">{{ generalConstants.NO_DATA_FOUND }}</td>
                                                    </tr>
                                                </template>
                                              </tbody>
                                            </table>
                                            <div v-if="isProductCheck" class="el-form-item__error">{{ ordersConstants.PLEASE_SELECT_AT_LEAST_ONE_PRODUCT }}</div> 
                                          </template>
                                        </div>
                                        </template>
                                        <div class="separator border-2 my-5"></div>
                                        <div class="row mb-2 mt-3">
                                          <div class="col-md-3">
                                            <label class="required fs-6 fw-semibold">{{ordersConstants.SHIPPING_METHOD}}</label>
                                            <el-form-item prop="shipping_method" class="mb-0">
                                              <el-select
                                                  size="large"
                                                  class="w-100"
                                                  v-model="formData.shipping_method"
                                                  filterable
                                                  :placeholder="ordersConstants.SHIPPING_METHOD"
                                                  multiple
                                                >
                                                  <el-option
                                                    v-for="methods in shippingMethods"
                                                    :key="methods.shipping_method_id"
                                                    :label="methods.shipping_method"
                                                    :value="methods.shipping_method_id"
                                                  />
                                                </el-select>
                                            </el-form-item>
                                          </div>
                                          <div class="col-md-3" v-if="isDisplayTrackingNumber">
                                            <label class="required fs-6 fw-semibold">{{ordersConstants.TRACKING_NO}}</label>
                                            <el-form-item prop="tracking_no" class="mb-0">
                                            <el-input size="large" v-model="formData.tracking_no" :placeholder="ordersConstants.TRACKING_NO"/> 
                                            </el-form-item>
                                          </div>
                                        </div>
                                          <button 
                                          type="button"
                                          class="btn btn-primary me-2"
                                          :data-kt-indicator="generateShipmentLoading ? 'on' : null"
                                          @click="generateShipment"
                                          :disabled="disabled"
                                          v-if="isDisplayTrackingNumber && formData.shipping_method.length > 0"
                                          >
                                          <span v-if="!generateShipmentLoading" class="indicator-label">{{ ordersConstants.CREATE_SHIPMENT }}</span>
                                          <span v-if="generateShipmentLoading" class="indicator-progress">{{ ordersConstants.CREATE_SHIPMENT }}
                                            <span
                                              class="spinner-border spinner-border-sm align-middle ms-2 fs-5"
                                            ></span>
                                          </span>
                                        </button>
                                        <button 
                                          type="button"
                                          class="btn btn-primary"
                                          @click="getShippingRates"
                                          :data-kt-indicator="getShipmentRatesLoading ? 'on' : null"
                                          :disabled="getShipmentRatesDisabled"
                                          v-if="!isDisplayTrackingNumber && formData && formData.shipping_method && formData.shipping_method.length > 0"
                                          >
                                          <span v-if="!getShipmentRatesLoading" class="indicator-label">{{ ordersConstants.GET_RATES }}</span>
                                          <span v-if="getShipmentRatesLoading" class="indicator-progress">{{ ordersConstants.GET_RATES }}
                                            <span
                                              class="spinner-border spinner-border-sm align-middle ms-2 fs-5"
                                            ></span>
                                          </span>
                                        </button>
                                        <div class="row mt-5">
                                          <!-- Shipping Rates Listing :: Start -->
                                          <ul
                                            class="nav nav-stretch fs-5 fw-semobold nav-line-tabs nav-line-tabs-2x border-transparent"
                                            role="tablist"
                                          >
                                          <template v-for="(shippingMethod, index) in shippingRates.shippingMethodsName" :key="index">
                                            <li class="nav-item" role="presentation">
                                              <a
                                                id="kt_referrals_year_tab"
                                                class="nav-link text-active-primary"
                                                :class="index==0 ? 'active': ''"
                                                data-bs-toggle="tab"
                                                role="tab"
                                                :href="'#shipping_rates_'+shippingMethod"
                                              >
                                                {{shippingMethod}}
                                              </a>
                                            </li>
                                          </template>
                                          </ul>
                                          <!--end::Tab nav-->
                                          <div id="kt_referred_users_tab_content" class="tab-content">
                                            <template v-for="(shippingMethod, index) in shippingRates.shippingMethodsRates" :key="index">
                                              <div :id="'shipping_rates_'+index" class="py-0 tab-pane fade" :class="shippingRates.shippingMethodsName.indexOf(index)==0 ? 'active show': ''" role="tabpanel">
                                                  <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer ">
                                                  <thead>
                                                    <tr class="fw-bolder fs-5 text-gray-800">
                                                      <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0 text-center"></th>
                                                      <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">{{ ordersConstants.SHIPPING_TYPE }}</th>
                                                      <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0 text-center">{{ ordersConstants.SHIPPING_COST }}</th>
                                                      <!-- <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0 text-center">{{ ordersConstants.BUSINESS_DAYS_IN_TRANSIST }}</th> -->
                                                      <th class="text-gray-400 fw-bold fs-7 text-uppercase gs-0 text-center">{{ ordersConstants.ESTIMATE_DELIVERY_DATE }}</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody class="fw-bold text-gray-600">
                                                    <template v-if="shippingMethod.data">
                                                      <template v-for="(rate, index) in shippingMethod.data" :key="index" >
                                                        <tr>
                                                          <td class="text-center">
                                                            <el-radio-group class="is_default_radio" v-model="formData.shipping_type">
                                                              <el-radio
                                                                :key="index"
                                                                :label="rate.shipping_method_code"
                                                                :value="rate.shipping_method_code"
                                                                >
                                                              </el-radio>
                                                            </el-radio-group>
                                                          </td>
                                                          <td>{{rate.shipping_method_name}}
                                                            <span class="badge badge-light-success fs-7 ms-2" v-if="rate.guaranteed && rate.guaranteed == 1">Guaranteed</span>
                                                          </td>
                                                          <td class="text-center">{{ formatPrice(rate.shipping_amount) }}</td>
                                                          <td class="text-center">
                                                            {{ formatDate(rate.arrival_date) }}
                                                          </td>
                                                        </tr>
                                                      </template>
                                                    </template>
                                                    <template v-else>
                                                        <tr>
                                                          <td class="align-center" colspan="5">
                                                            <p style="text-align: center">{{ shippingMethod.msg }}</p>
                                                          </td>
                                                        </tr>
                                                    </template>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </template>
                                          </div>
                                          <!-- Shipping Rates Listing :: End -->
                                        </div>
                                        <button 
                                          type="button"
                                          class="btn btn-primary me-2"
                                          :data-kt-indicator="generateShipmentLoading ? 'on' : null"
                                          @click="generateShipment"
                                          :disabled="disabled"
                                          v-if="shippingRates.shippingMethodsRates && !isDisplayTrackingNumber && formData.shipping_type != ''"
                                          >
                                          <span v-if="!generateShipmentLoading" class="indicator-label">{{  ordersConstants.CREATE_SHIPMENT }}</span>
                                          <span v-if="generateShipmentLoading" class="indicator-progress">{{  ordersConstants.CREATE_SHIPMENT }}
                                            <span
                                              class="spinner-border spinner-border-sm align-middle ms-2 fs-5"
                                            ></span>
                                          </span>
                                        </button>
                                      </el-form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <!-- Create Shipment Form :: End -->
                    </div> 
                    <!-- Shipment :: End -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value && orderDetails.value.extra_fields">
            <div class="card-header">
              <div class="card-title">
                <h2>{{ordersConstants.ORDER_EXTRA_FIELDS}}</h2>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="d-flex flex-wrap">
                <div class="flex-equal me-5">
                  <table class="table table-flush fw-bold gy-1">
                    <tbody>
                      <template v-for="(value, key) in orderDetails.value.extra_fields" :key="key">
                        <tr v-if="value && value != null && value != ''">
                            <td class="text-muted min-w-200px w-200px">{{formatText(key)}} : </td>
                            <td class="text-gray-800">
                            <template v-if="typeof value === 'object'">
                                <span v-for="(item, itemkey) in value" :key="itemkey">
                                {{formatText(itemkey)}} : {{(item) ? item : '-'}} <br>
                                </span>
                            </template>
                            <template v-else>
                                {{value}}
                            </template>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Tab pane-->
      <!--begin::Tab pane-->
      <div class="tab-pane fade" id="kt_ecommerce_sales_order_history" role="tab-panel">
        <!--begin::Orders-->
        <div class="d-flex flex-column gap-7 gap-lg-10">
          <!--begin::Order history-->
          <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value">
            <!--begin::Card header-->
            <div class="card-header">
              <div class="card-title">
                <h2>Order History</h2>
              </div>
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
              <div class="table-responsive">
                <!--begin::Table-->
                <Datatable
                  :table-data="orderHistoryTableData"
                  :table-header="orderHistoryTableHeader"
                  :empty-table-text="noDataFound"
                  :enable-items-per-page-dropdown="true"
                >
                  <template v-slot:cell-created_at="{ row: data }">
                    {{ formatDateTime(data.created_at) }}
                  </template>
                  <template v-slot:cell-comment="{ row: data }">
                    <div v-if="data.order_product || data.order_product_status">
                      <span class="text-primary" v-if="data.order_product.product_name">
                        {{ data.order_product.product_name }}
                      </span>
                      <span class="text-primary" v-else>
                        NA
                      </span>
                      <span class="text-primary" v-if="data.order_product_status">
                         - {{ data.order_product_status.title }}
                      </span>
                      <span class="text-primary" v-if="data.product_sku_id">
                         -
                        <span v-if="data.sku.modify_sku">
                          {{ data.sku.modify_sku }}
                        </span>
                        <span v-else>
                          {{ data.sku.sku }}
                        </span>
                      </span>
                    </div>
                    <span>
                      {{ data.comment }}
                    </span>
                  </template>
                  <template v-slot:cell-created_by="{ row: data }">
                    {{ data.user.name }}
                  </template>
                </Datatable>
                <!--end::Table-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Tab pane-->
    </div>
    <!--end::Tab content-->

    <UpdateOrderProductStatus :orderProductId="orderProductId" :orderId="orderId" :orderProductStatusId="statusId" ref="updateProps" @statusUpdate="updateOrderProductItems"></UpdateOrderProductStatus>

    <div v-if="permission.isDownloadShipmentLabelAllowed">
      <GenerateOrderShipmentModal
        @getDetails="updateOrderProductItems"
        ref="prepareshipmentData"
      ></GenerateOrderShipmentModal>
    </div>
  <!-- Update Address Modal :: Start -->
  <div class="modal fade" tabindex="-1" id="update_address">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
						<template v-if="modalFormData.type == 'to'">
            {{ ordersConstants.TO_ADDRESS }}
						</template>
						<template v-else-if="modalFormData.type == 'from'">
            {{ ordersConstants.FROM_ADDRESS }}
						</template>
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <el-form :model="modalFormData" :rules="modalFormRules" ref="modalFormRef" class="w-100">
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.NAME }}</label>
                <el-form-item prop="name">
                  <el-input
                    size="large"
                    v-model="modalFormData.name"
                    type="text"
                    :placeholder="ordersConstants.NAME"
                  />
                </el-form-item>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-6 fw-semibold mb-2"> {{ ordersConstants.COMPANY }}</label>
                <el-form-item prop="company">
                  <el-input
                    size="large"
                    v-model="modalFormData.company"
                    type="text"
                    :placeholder="ordersConstants.COMPANY"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.ADDRESS_1 }}</label>
                <el-form-item prop="address1">
                  <el-input
                    size="large"
                    v-model="modalFormData.address1"
                    type="text"
                    :placeholder="ordersConstants.ADDRESS_1"
                  />
                </el-form-item>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-6 fw-semibold mb-2"> {{ ordersConstants.ADDRESS_2 }}</label>
                <el-form-item prop="address2">
                  <el-input
                    size="large"
                    v-model="modalFormData.address2"
                    type="text"
                    :placeholder="ordersConstants.ADDRESS_2"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.CITY }}</label>
                <el-form-item prop="city">
                  <el-input
                    size="large"
                    v-model="modalFormData.city"
                    type="text"
                    :placeholder="ordersConstants.CITY"
                  />
                </el-form-item>
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.STATE }}</label>
                <el-form-item prop="state">
                  <el-input
                    size="large"
                    v-model="modalFormData.state"
                    type="text"
                    :placeholder="ordersConstants.STATE"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.STATE_CODE }}</label>
                <el-form-item prop="state_code">
                  <el-input
                    size="large"
                    v-model="modalFormData.state_code"
                    type="text"
                    :placeholder="ordersConstants.STATE_CODE"
                  />
                </el-form-item>
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.COUNTRY }}</label>
                <el-form-item prop="country">
                  <el-input
                    size="large"
                    v-model="modalFormData.country"
                    type="text"
                    :placeholder="ordersConstants.COUNTRY"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.COUNTRY_CODE }}</label>
                <el-form-item prop="country_code">
                  <el-input
                    size="large"
                    v-model="modalFormData.country_code"
                    type="text"
                    :placeholder="ordersConstants.COUNTRY_CODE"
                  />
                </el-form-item>
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.POSTAL_CODE }}</label>
                <el-form-item prop="postal_code">
                  <el-input
                    size="large"
                    v-model="modalFormData.postal_code"
                    type="text"
                    :placeholder="ordersConstants.POSTAL_CODE"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-semibold mb-2"> {{ ordersConstants.PHONE_NUMBER }}</label>
                <el-form-item prop="phone_number">
                  <el-input
                    size="large"
                    v-model="modalFormData.phone_number"
                    type="text"
                    :placeholder="ordersConstants.PHONE_NUMBER"
                  />
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="modal-footer">
          <button
            id="split-job-close-btn"
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            {{ ordersConstants.CLOSE }} 
          </button>
          <button 
            type="button"
            class="btn btn-primary"
            :data-kt-indicator="saveLoader ? 'on' : null"
            @click="submitForm(modalFormRef)"
          >
            <span v-if="!saveLoader" class="indicator-label">{{ generalConstants.SAVE }}</span>
            <span v-if="saveLoader" class="indicator-progress">{{ generalConstants.SAVE }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2 fs-5"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Update Address Modal :: End -->
  <!-- Select Address Modal :: Start -->
  <div class="modal fade" tabindex="-1" id="select_address">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ ordersConstants.CHOOSE_FROM_ADDRESS}}
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="select_address_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-8">
            <div class="col-xl-12">
              <div class="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                <template v-for="(address, i) in returnAddresses" :key="i">
                <div class="col-md-6 col-lg-12 col-xxl-6">
                  <label class="btn btn-outline btn-outline-dashed btn-outline-default active d-flex text-start p-6" data-kt-button="true">
                    <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1 is_default_radio">
                      <el-radio-group v-model="selectedAddress">
                        <el-radio
                          :key="i"
                          :label="i"
                          :value="i"
                          ></el-radio>
                      </el-radio-group>
                    </span>
                    <span class="ms-5">
                      <div class="d-flex align-items-center fs-5 fw-bolder">{{address.name}}
                        <span class="badge badge-light-success fs-7 ms-2" v-if="address.trading_partner_id == 0">Default Return Address</span></div>
                        <div class="fs-6 fw-bold text-gray-600">
                          <span v-if="address && address.address1">{{address.address1}}<br></span>
                          <span v-if="address && address.address2">{{address.address2}}<br></span>
                          <span v-if="address && address.city">{{address.city}}<br></span>
                          <span v-if="address && address.state">{{address.state}} <span v-if="address && address.state_code">({{address.state_code}})</span> <span v-if="address && address.postal_code"> - {{address.postal_code}} </span><br></span>
                          <span v-if="address && address.country">{{address.country}}<span v-if="address && address.country_code"> ({{address.country_code}})</span>.</span>
                          <span v-if="address && address.phone_number"><br><span class="fw-bold text-gray-600">{{ ordersConstants.PHONE_NUMBER }}: </span>{{address.phone_number}}</span>
                          <span v-if="address && address.company"><br><span class="fw-bold text-gray-600">{{ ordersConstants.COMPANY }}: </span>{{address.company}}</span>
                        </div>
                    </span>
                  </label>
                </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="split-job-close-btn"
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            {{ ordersConstants.CLOSE }} 
          </button>
          <button 
            type="button"
            class="btn btn-primary"
            @click="updateToAddress()"
          >
            <span class="indicator-label">{{ ordersConstants.SELECT }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Select Address Modal :: End -->
  <!-- Suggested Address Modal :: Start -->
  <div class="modal fade" tabindex="-1" id="suggested_address">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ ordersConstants.SUGGESTED_ADDRESSES}}
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="suggested_address_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-8">
            <div class="col-xl-12">
              <div class="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                <template v-for="(suggestedAddress, i) in suggestedAddresses.value" :key="i">
                <div class="col-md-6 col-lg-12 col-xxl-6">
                  <label class="btn btn-outline btn-outline-dashed btn-outline-default active d-flex text-start p-6" data-kt-button="true">
                    <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1 is_default_radio">
                      <el-radio-group v-model="selectedSuggestedAddress">
                        <el-radio
                          :key="i"
                          :label="i"
                          :value="i"
                          ></el-radio>
                      </el-radio-group>
                    </span>
                    <span class="ms-5">
                      <div class="d-flex align-items-center fs-5 fw-bolder">{{suggestedAddress.name}}</div>
                        <div class="fs-6 fw-bold text-gray-600">
                          <span v-if="suggestedAddress && suggestedAddress.address1">{{suggestedAddress.address1}}<br></span>
                          <span v-if="suggestedAddress && suggestedAddress.address2">{{suggestedAddress.address2}}<br></span>
                          <span v-if="suggestedAddress && suggestedAddress.city">{{suggestedAddress.city}}<br></span>
                          <span v-if="(suggestedAddress && suggestedAddress.state) || (suggestedAddress && suggestedAddress.state_code)">{{suggestedAddress.state}} <span v-if="suggestedAddress && suggestedAddress.state_code">({{suggestedAddress.state_code}})</span> <span v-if="suggestedAddress && suggestedAddress.postal_code"> - {{suggestedAddress.postal_code}} </span><br></span>
                          <span v-if="(suggestedAddress && suggestedAddress.country) || (suggestedAddress && suggestedAddress.country_code)">{{suggestedAddress.country}}<span v-if="suggestedAddress && suggestedAddress.country_code"> ({{suggestedAddress.country_code}})</span>.</span>
                        </div>
                    </span>
                  </label>
                </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="suggested_address_close_button"
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            {{ ordersConstants.CLOSE }} 
          </button>
          <button 
            type="button"
            class="btn btn-primary"
            @click="updateToAddressBySuggestedAddress()"
          >
            <span class="indicator-label">{{ ordersConstants.SELECT }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Suggested Address Modal :: End -->
  </div>
</template>
<style scoped>
.is_default_radio >>> .el-radio__label {
  display: none !important;
}
</style>
<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { formatPrice, formatDate, formatDateTime, formatText } from "@/core/helpers/common";
import { useRouter } from "vue-router";
import { createOrderPoductOptions, createOrderPoductFiles } from "@/composable/order.js";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import UpdateOrderProductStatus from "@/components/modals/forms/UpdateOrderProductStatus.vue";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { notificationFire } from "@/composable/notification.js";
import GenerateOrderShipmentModal from "@/components/modals/forms/GenerateOrderShipmentModal.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { ElMessageBox } from "element-plus";

export default {
  name: "order-detail",
  components: {
    Datatable,
    UpdateOrderProductStatus,
    GenerateOrderShipmentModal
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const orderId = ref();
    const noDataFound = ref("No Data Found!");
    const loading = ref(false);
    const orderDetails = reactive({});
    const route = useRoute();
    const fullscreenLoading = ref(false);
    const tableData = ref([]);
    const orderHistoryTableData = ref([]);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const orderProductId = ref(0);
    const statusId = ref(0);
    const updateProps = ref(null);
    const prepareshipmentData = ref(null);
    const orderShipmentData = ref([]);
    const isGenerateShippingLabel = ref(true);
    const returnAddresses = ref({});
    const fromAddress = ref([]);
    const shipmentType = ref([
      { text: ordersConstants.ORDER_WISE, value: 1 },
      { text: ordersConstants.ORDER_PRODUCT_WISE, value: 2 }
    ]);
    const orderShipmentType = ref(1);
    const shippingMethods = [];
    const formRef = ref(null);
    const addressValidationMessage = ref(null);
    const addressValidationClass = ref(null);
    const addressValidationResponse = ref(null);
    const isDisabledOrderShipment = ref(false);
    const isDisplayTrackingNumber = ref(false);
    const shippingRates = reactive({
      shippingMethodsName: [],
      shippingMethodsRates: []
    });
    const selectedShippingRate = ref(null);
    const getShipmentRatesDisabled = ref(false);
    const getShipmentRatesLoading = ref(false);
    const masterPackages = {};
    const masterPackageData = [];
    const saveLoader = ref(false);
    const modalFormRef = ref(null);
    const generateShipmentLoading = ref(false);
    const disabled = ref(false);
    const isRegenerateShipmentLabel = ref(true);
    const shipmentRegenerateId = ref(0);
    const shippingTypeData = ref([]);
    const shippingMethodTypes = {};
    const isProductCheck = ref(false);
    const suggestedAddresses = reactive({});
    const selectedSuggestedAddress = ref(null);
    const selectedAddress = ref(null);

    const dimensionUnit = ref([
      { text: ordersConstants.INCH, value: "IN" },
      { text: ordersConstants.CENTIMETER, value: "CM" },
    ]);
    const weightUnit = ref([
      { text: ordersConstants.LBS, value: "LBS" },
      { text: ordersConstants.KILOGRAM, value: "KG" },
    ]);

    //Modal form data
    const modalFormData = reactive({
      type: "",
      name: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      state_code: "",
      country: "",
      country_code: "",
      postal_code: "",
      phone_number: ""
    });

    // Modal form rules
    const modalFormRules = ref({
      name: [
        {
          required: true,
          message: ordersConstants.NAME_REQUIRED,
          trigger: "change",
        },
      ],
      address1: [
        {
          required: true,
          message: ordersConstants.ADDRESS_1_REQUIRED,
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: ordersConstants.CITY_REQUIRED,
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: ordersConstants.STATE_REQUIRED,
          trigger: "change",
        },
      ],
      state_code: [
        {
          required: true,
          message: ordersConstants.STATE_CODE_REQUIRED,
          trigger: "change",
        },
      ],
      country: [
        {
          required: true,
          message: ordersConstants.COUNTRY_REQUIRED,
          trigger: "change",
        },
      ],
      country_code: [
        {
          required: true,
          message: ordersConstants.COUNTRY_CODE_REQUIRED,
          trigger: "change",
        },
      ],
      postal_code: [
        {
          required: true,
          message: ordersConstants.POSTAL_CODE_REQUIRED,
          trigger: "change",
        },
      ],
      phone_number: [
        {
          required: true,
          message: ordersConstants.PHONE_NUMBER_REQUIRED,
          trigger: "change",
        },
      ]
    });

    //Validation rules for shipment form 
    const rules = ref({
      shipping_method: [
        {
          required: true,
          message: ordersConstants.SHIPPING_METHOD_IS_REQUIRED,
          trigger: "change"
        },
      ],
      shipping_type: [
        {
          required: true,
          message: ordersConstants.SHIPPING_TYPE_IS_REQUIRED,
          trigger: "change"
        }
      ],
      tracking_no: [
        {
          required: true,
          message: ordersConstants.TRACKING_NO_IS_REQUIRED,
          trigger: "change"
        }
      ]
    });

    //Shipment form data 
    const formData = reactive({
      orderPackages: [
        {
          weight_unit: "",
          weight: "",
          quantity: "",
          dimension_unit: "",
          length: "",
          height: "",
          width: ""
        },
      ],
      tracking_no: null,
      productPackages: [],
      shipping_method: [],
      shipping_type: ""
    });
    const tableRowClassName = (row) => {
      if (route.params.product_id == row.row.product_id) {
        return 'success-row'
      }
      return ''
    }
    const permission = reactive({
      isReadOrderHistoryAllowed: false,
      isDownloadShipmentLabelAllowed: false
    })

    //Prepare Shipment Label form data
    const manageShipmentLabel = async(productData, isDownload) => {
      prepareshipmentData.value.prepareshipmentData(productData, isDownload);
    }

    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.IMAGE,
        key: "image",
        sortable: false,
        width: "w-200px",
        alignment: 'text-center'
      },
      {
        name: ordersConstants.PRODUCT_DETAILS,
        key: "productDetails",
        sortable: false,
        width: "w-300px",
      },
      {
        name: ordersConstants.ADDITIONAL_INFORMATION,
        key: "additionalInfo",
        width: "w-250px",
        sortable: false,
      },
      {
        name: "Shipping Type & Method",
        key: "shippingTypeAndMethods",
        width: "w-250px",
        sortable: false,
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        sortable: false,
        width: "w-150px"
      },
      {
        name: generalConstants.QUANTITY,
        key: "quantity",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.PRICE,
        key: "price",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
        visible: true,
      },
    ]);

    //Order History table header data
    const orderHistoryTableHeader = ref([
      {
        name: 'Date Added',
        key: "created_at",
        sortable: true,
        width: "w-100px"
      },
      {
        name: 'Comment',
        key: "comment",
        sortable: false,
        width: "w-300px"
      },
      {
        name: 'Added By',
        key: "created_by",
        sortable: true,
        width: "w-100px"
      }
    ]);
    
    const getOrderDetails = async () => {
      fullscreenLoading.value = true;
      await ApiService.query("orders/"+orderId.value)
      .then(({ data }) => {
        orderDetails.value = data.data;
        if(orderDetails.value.order_history) {
            orderHistoryTableData.value =  orderDetails.value.order_history;
        }
        let orderProductItems = data.data.order_products;
        tableData.value.splice(0, tableData.value.length, ... data.data.order_products);
        if (orderProductItems.length > 0) {
          orderProductItems.forEach((value) => {
            value['product_additional_info'] = createOrderPoductOptions(value)['productAdditionalInfo'];
            value["product_image"] = createOrderPoductFiles(value)['productFilesInfo'];
          });
        }
        fullscreenLoading.value = false;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          if (status == 404) {
            router.push({ name: "orders" });
          } else {
            notificationFire(message, "error");
          }
        } 
      });
    }

    const updateOrderProductItems = () => {
      getOrderDetails();
    }

    const manageOrderProductstatus = (id, status) => {
      orderProductId.value = parseInt(id);
      statusId.value = status;
      updateProps.value.updateProps(statusId.value);
    }

    let fromRoutePath = "orders";
    let fromRouteName = "Orders";
    if(route.name == "orderDetails"){
        fromRoutePath = "order-products";
        fromRouteName = "Order Products";
    }

    const openPendingOrderDetailPage = (orderId) => {
      router.push('/pending-orders/'+orderId)
    }

    const openDetailPage = (orderId,orderProductId) => {
      router.push('/' + fromRoutePath + '/product/'+orderId+'/'+orderProductId)
    }

    let scroll = function (e) { e.preventDefault() };

    const stopScrolling = () => {    
      document.addEventListener("wheel", scroll, { passive:false })
    }

    const closeImage = () => {
      document.removeEventListener("wheel", scroll)
    }

    const downloadFiles = (orderId, orderProductId, index) => {
      fullscreenLoading.value = true;
      ApiService.query("order-files-download/"+orderId+"/"+orderProductId)
      .then(({ data }) => {
        if (data.status) {
          tableData.value[index].is_files_downloaded = data.is_files_downloaded;
          if(data.imageData.success){
            if(data.imageData.success['P'+orderProductId].page1.image){
              tableData.value[index].product_image.push(process.env.VUE_APP_API_URL+"../../../orders/"+orderId+'/'+data.imageData.success['P'+orderProductId].page1.image);
            }
            if(data.imageData.success['P'+orderProductId].page1.thumb){
              tableData.value[index].product_image.push(process.env.VUE_APP_API_URL+"../../../orders/"+orderId+'/'+data.imageData.success['P'+orderProductId].page1.thumb);
            }
          }
          notificationFire(data.msg, data.alertType);
        } else {
          notificationFire(data.msg, data.alertType);
        }
        fullscreenLoading.value = false;
      })
      .catch((error) => {
        fullscreenLoading.value = false;
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          if (status == 404) {
            router.push({ name: "orders" });
          } else {
            notificationFire(message, "error");
          }
        } 
      });
    }

    //Get all shipping package
    const getShipmentDetails = async () => {
      await ApiService.query("order-shipment?order_id=" + orderId.value)
        .then(({ data }) => {
          if (data.data) {
            if (data.data) {
              orderShipmentData.value = [];
              orderShipmentData.value.splice(0, orderShipmentData.value.length, ...data.data);
            }
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "orders" });
            } else {
              notificationFire(message, "error");
            }
          } 
        });
    };

    const showHideProducts = (id) => {
      let elementClass = document.getElementById("prodctsUpDown" + id).classList;
      if (elementClass.contains("bi-caret-up-square")) {
        elementClass.add("bi-caret-down-square");
        elementClass.remove("bi-caret-up-square");
        document.getElementById("orderProductsTr" + id).classList.add("d-none");
      } else {
        elementClass.remove("bi-caret-down-square");
        elementClass.add("bi-caret-up-square");
        document.getElementById("orderProductsTr" + id).classList.remove("d-none");
      }
    };

    //Download shipment label
    const downloadShipmentLabel = (shipmentId) => {
      ApiService.post("download-shipment-label", {
        shipment_id: shipmentId
      })
        .then((response) => {
          if (response.data) {
            let status = response.status;
            if (status == 200) {
              let path = process.env.VUE_APP_API_URL;
              path = path.replace("/api/v1", "");
              window.open(path + response.data.data, "_blank");
            }
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "orders" });
            } else {
              notificationFire(message, "error");
            }
          } 
        })
        .finally(() => {
          loading.value = false;
        });
    };

     //Get Shipping Methods and Types
    const getShippingMethodsAndType = async () => {
      await ApiService.query("shipping-method")
        .then(({ data }) => {
          if (data.data) {
            data.data.forEach((value, key) => {
              shippingMethods[key] = {};
              shippingMethods[key]["shipping_method_id"] = value["shipping_method_id"];
              shippingMethods[key]["shipping_method"] = value["shipping_method"];
              shippingMethods[key]["is_offline"] = value["shipping_mode"];
            });
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "orders" });
            } else {
              notificationFire(message, "error");
            }
          } 
        });
    };

    //Get shipping rates
    const getShippingRates = async () => {
      if (!formRef.value) return;
      await formRef.value.validate((valid) => {
        if (orderShipmentType.value == 2) {
          var checkboxes = document.querySelectorAll("input[name='product_checkbox']");
          var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
          if (!checkedOne) {
            isProductCheck.value = true;
            return;
          }
        }
        if (valid) {
          if (addressValidationClass.value == "danger") {
            notificationFire(ordersConstants.INVALID_TO_ADDRESS_ERROR_MESSAGE, "error");
            return;
          }
          
          let updatedformData = JSON.parse(JSON.stringify(formData));
          if (orderShipmentType.value == 1) {
            //order shipment
            updatedformData.packageConfig = [updatedformData.orderPackages];
          } else {
            //order product shipment
            let filterProductsPackage = {};
              Object.keys(updatedformData.productPackages).forEach((key) => {
                let isChecked = document.getElementById("product_" + key).checked;
                if (updatedformData.productPackages[key].length > 0 && updatedformData.productPackages[key] != null && isChecked) {
                  filterProductsPackage[key] = updatedformData.productPackages[key];
                }
              });
              updatedformData.packageConfig = filterProductsPackage;
            // let filterProductsPackage = {};
            // Object.keys(updatedformData.productPackages).forEach((key) => {
            //   if (updatedformData.productPackages[key].length > 0 && updatedformData.productPackages[key] != null) {
            //     filterProductsPackage[key] = updatedformData.productPackages[key];
            //   }
            // });
            // updatedformData.packageConfig = filterProductsPackage;
          }
          loading.value = getShipmentRatesDisabled.value = getShipmentRatesLoading.value = true;
          ApiService.post("shipping-rates", {
            _method: "POST", 
            shipping_method: formData.shipping_method,
            fromAddress: fromAddress.value,
            toAddress: orderDetails.value.shipping_address,
            packages: updatedformData.packageConfig
          })
          .then(async (response) => {
            if (response.data) {
              let status = response.status;
              if (status == 200 || status == 201) {
                shippingRates.shippingMethodsName = Object.keys(response.data.data);
                shippingRates.shippingMethodsRates = response.data.data;
              }
              loading.value = getShipmentRatesDisabled.value = getShipmentRatesLoading.value = false;
            }
          })
          .catch(async (error) => {
            loading.value = getShipmentRatesDisabled.value = getShipmentRatesLoading.value = false;
            let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              if (status == 404) {
                router.push({ name: "orders" });
              } else {
                notificationFire(message, "error");
              }
            } 
          });
        }
      });
    };

    const getMasterPackageRowDataForOrder = async (masterPackageId, index) => {
      let masterPackage = masterPackageData.filter((val) =>
        val.master_package_id == masterPackageId
      );
      formData.orderPackages[index].weight_unit = masterPackage[0].weight_class;
      formData.orderPackages[index].weight = masterPackage[0].box_weight;
      formData.orderPackages[index].dimension_unit = masterPackage[0].length_class;
      formData.orderPackages[index].length = masterPackage[0].package_length;
      formData.orderPackages[index].height = masterPackage[0].package_height;
      formData.orderPackages[index].width = masterPackage[0].package_width;
    };

    const getMasterPackageRowDataForOrderProduct = async (masterPackageId, orderProductId, index) => {
      let masterPackage = masterPackageData.filter((val) =>
        val.master_package_id == masterPackageId
      );
      formData.productPackages[orderProductId][index].weight_unit = masterPackage[0].weight_class;
      formData.productPackages[orderProductId][index].weight = masterPackage[0].box_weight;
      formData.productPackages[orderProductId][index].dimension_unit = masterPackage[0].length_class;
      formData.productPackages[orderProductId][index].length = masterPackage[0].package_length;
      formData.productPackages[orderProductId][index].height = masterPackage[0].package_height;
      formData.productPackages[orderProductId][index].width = masterPackage[0].package_width;
    };

    //Get all master package
    const getMasterPackage = async () => {
      await ApiService.query("get-active-master-package")
        .then(({ data }) => {
          if (data.data) {
            data.data.forEach((value, key) => {
              masterPackages[key] = {};
              masterPackages[key]["master_package_id"] = value["master_package_id"];
              masterPackages[key]["package_name"] = value["package_name"];
              masterPackageData.push(value);
            });
          }
        })
        .catch((error) => {
         let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              if (status == 404) {
                // router.push({ name: "orders" });
              } else {
                notificationFire(message, "error");
              }
            } 
        });
    };

     //Add new record for order package configuration
    const addOrderPackage = () => {
      formData.orderPackages.push({
        master_package_id: "",
        weight_unit: "",
        weight: "",
        quantity: "",
        dimension_unit: "",
        length: "",
        height: "",
        width: ""
      });
    };

     //Add new record for product package configuration
    const addProductPackage = (productId) => {
      formData.productPackages[productId].push({
        master_package_id: "",
        weight_unit: "",
        weight: "",
        quantity: "",
        dimension_unit: "",
        length: "",
        height: "",
        width: ""
      });
    };

    //Delete a package configuration of order product
    const deletePackageConfigurationOfOrderProduct = async (productId, shipmentPackage) => {
      var index = formData.productPackages[productId].indexOf(shipmentPackage);
      if (index > -1) {
        formData.productPackages[productId].splice(index, 1);
      }
    };

    //Delete a package configuration of order
    const deletePackageConfigurationOfOrder = async (shipmentPackage) => {
      var index = formData.orderPackages.indexOf(shipmentPackage);
      if (index > -1) {
        formData.orderPackages.splice(index, 1);
      }
    };

    //Get return addresses (from address)
    const getReturnAddresses = () => {
      ApiService.query("return-addresses/" + orderDetails.value.trading_partner_id)
        .then(({ data }) => {
          if (data.data) {
            returnAddresses.value = data.data;
            data.data.forEach((value, key) => {
              if (value.trading_partner_id == 0) {
                fromAddress.value = value;
                selectedAddress.value = key;
              }
            });
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "orders" });
            } else {
              notificationFire(message, "error");
            }
          } 
        })
    };

     //Show the update address modal
    const showModal = (addressType) => {
      if (addressType == "to") {
        modalFormData.type = "to";
        modalFormData.name = (orderDetails.value.shipping_address.name) ? orderDetails.value.shipping_address.name : ''; 
        modalFormData.company = (orderDetails.value.shipping_address.company) ? orderDetails.value.shipping_address.company : '';
        modalFormData.address1 = (orderDetails.value.shipping_address.address1) ? orderDetails.value.shipping_address.address1 : '';
        modalFormData.address2 = (orderDetails.value.shipping_address.address2) ? orderDetails.value.shipping_address.address2 : '';
        modalFormData.city = (orderDetails.value.shipping_address.city) ? orderDetails.value.shipping_address.city : '';
        modalFormData.state = (orderDetails.value.shipping_address.state) ? orderDetails.value.shipping_address.state : '';
        modalFormData.state_code = (orderDetails.value.shipping_address.state_code) ? orderDetails.value.shipping_address.state_code : '';
        modalFormData.country = (orderDetails.value.shipping_address.country) ? orderDetails.value.shipping_address.country : '';
        modalFormData.country_code = (orderDetails.value.shipping_address.country_code) ? orderDetails.value.shipping_address.country_code : '';
        modalFormData.postal_code = (orderDetails.value.shipping_address.postcode) ? orderDetails.value.shipping_address.postcode : '';
        modalFormData.phone_number = (orderDetails.value.shipping_address.phone) ? orderDetails.value.shipping_address.phone : '';
      } else if (addressType == "from") {
        modalFormData.type = "from";
        modalFormData.name = (fromAddress.value.name) ? fromAddress.value.name : '';
        modalFormData.company = (fromAddress.value.company) ? fromAddress.value.company : '';
        modalFormData.address1 = (fromAddress.value.address1) ? fromAddress.value.address1 : '';
        modalFormData.address2 = (fromAddress.value.address2) ? fromAddress.value.address2 : '';
        modalFormData.city = (fromAddress.value.city) ? fromAddress.value.city : '';
        modalFormData.state = (fromAddress.value.state) ? fromAddress.value.state : '';
        modalFormData.state_code = (fromAddress.value.state_code) ? fromAddress.value.state_code : '';
        modalFormData.country = (fromAddress.value.country) ? fromAddress.value.country : '';
        modalFormData.country_code = (fromAddress.value.country_code) ? fromAddress.value.country_code : '';
        modalFormData.postal_code = (fromAddress.value.postal_code) ? fromAddress.value.postal_code : '';
        modalFormData.phone_number = (fromAddress.value.phone_number) ? fromAddress.value.phone_number : '';
        modalFormData.is_default = (fromAddress.value.is_default) ? fromAddress.value.is_default : '';
      }
    };

    //Save address data
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          saveLoader.value = true;
          let shipping_address = {};
          shipping_address.name = modalFormData.name;
          shipping_address.company = modalFormData.company;
          shipping_address.address1 = modalFormData.address1;
          shipping_address.address2 = modalFormData.address2;
          shipping_address.city = modalFormData.city;
          shipping_address.state = modalFormData.state;
          shipping_address.state_code = modalFormData.state_code;
          shipping_address.country = modalFormData.country;
          shipping_address.country_code = modalFormData.country_code;
          shipping_address.postcode = modalFormData.postal_code;
          shipping_address.phone = modalFormData.phone_number;
          shipping_address.validate = 1;
          let request = [];
          if (modalFormData.type == "to") {
            request = ApiService.post("update-shipping-address/" + orderId.value, {
              _method: "PUT",
              shipping_address
            });
          } else if (modalFormData.type == "from") {
            let requestUrl = null;
            // let is_default = 1;
            if ((typeof fromAddress.value.trading_partner_address_id === "undefined")) {
              // requestUrl = "site-addresses";
            } else {
              // requestUrl = "site-addresses/" + fromAddress.value.trading_partner_address_id;
              // is_default = (typeof modalFormData.is_default !== "undefined") ? modalFormData.is_default : 0;
            }
            requestUrl = "trading-partner/address-update";
            let addressData = {};
            addressData.name = modalFormData.name;
            addressData.company = modalFormData.company;
            addressData.address1 = modalFormData.address1;
            addressData.address2 = modalFormData.address2;
            addressData.city = modalFormData.city;
            addressData.state = modalFormData.state;
            addressData.state_code = modalFormData.state_code;
            addressData.country = modalFormData.country;
            addressData.country_code = modalFormData.country_code;
            addressData.postal_code = modalFormData.postal_code;
            addressData.phone = modalFormData.phone_number;
            addressData.trading_partner_id = fromAddress.value.trading_partner_id;
            request = ApiService.post(requestUrl, {
              // _method: typeof fromAddress.value.trading_partner_address_id === "undefined" ? "POST" : "PUT",
              _method: "POST",
              addressData
              // is_default: is_default
            });
          }

          request
            .then((response) => {
              if (response.data) {
                let status = response.status;
                let message = response.data.message;
                if (status == 200 || status == 201) {
                  if (modalFormData.type == "to") {
                    orderDetails.value.shipping_address.name = shipping_address.name;
                    orderDetails.value.shipping_address.company = shipping_address.company;
                    orderDetails.value.shipping_address.address1 = shipping_address.address1;
                    orderDetails.value.shipping_address.address2 = shipping_address.address2;
                    orderDetails.value.shipping_address.city = shipping_address.city;
                    orderDetails.value.shipping_address.state = shipping_address.state;
                    orderDetails.value.shipping_address.state_code = shipping_address.state_code;
                    orderDetails.value.shipping_address.country = shipping_address.country;
                    orderDetails.value.shipping_address.country_code = shipping_address.country_code;
                    orderDetails.value.shipping_address.postcode = shipping_address.postcode;
                    orderDetails.value.shipping_address.phone = shipping_address.phone;
                    addressValidationClass.value = "success"; 
                    addressValidationMessage.value =  ordersConstants.ADDRESS_VARIFIED;
                  } else if (modalFormData.type == "from") {
                    fromAddress.value.name = shipping_address.name;
                    fromAddress.value.company = shipping_address.company;
                    fromAddress.value.address1 = shipping_address.address1;
                    fromAddress.value.address2 = shipping_address.address2;
                    fromAddress.value.city = shipping_address.city;
                    fromAddress.value.state = shipping_address.state;
                    fromAddress.value.state_code = shipping_address.state_code;
                    fromAddress.value.country = shipping_address.country;
                    fromAddress.value.country_code = shipping_address.country_code;
                    fromAddress.value.postal_code = shipping_address.postcode;
                    fromAddress.value.phone_number = shipping_address.phone;
                  }
                  document.getElementById("close_button").click();
                  notificationFire(message, generalConstants.NOTIFY_SUCCESS);
                }
              }
            })
            .catch((error) => {
              let response = error.response;
              if (response) {
                let status = response.status;
                let message = response.data.message;
                if (status == 404) {
                  router.push({ name: "orders" });
                } else {
                  notificationFire(message, "error");
                }
              } 
            })
            .finally(() => {
              saveLoader.value = false;
            });
        }
      });
    };

    const updateToAddressBySuggestedAddress = () => {
      if (selectedSuggestedAddress.value != null) {
        let suggestedAddress = suggestedAddresses.value[selectedSuggestedAddress.value];
        let shipping_address = {};
        shipping_address.name = orderDetails.value.shipping_address.name;
        shipping_address.company = orderDetails.value.shipping_address.company;
        shipping_address.address1 = suggestedAddress.address1;
        shipping_address.address2 = suggestedAddress.address2;
        shipping_address.city = suggestedAddress.city;
        shipping_address.state = orderDetails.value.shipping_address.state;
        shipping_address.state_code = suggestedAddress.state_code;
        shipping_address.country = orderDetails.value.shipping_address.country;
        shipping_address.country_code = suggestedAddress.country_code;
        shipping_address.postcode = suggestedAddress.postal_code;
        shipping_address.phone = orderDetails.value.shipping_address.phone;
        ApiService.post("orders/" + orderId.value, {
          _method: "PUT",
          shipping_address
        })
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              orderDetails.value.shipping_address.name = shipping_address.name;
              orderDetails.value.shipping_address.company = shipping_address.company;
              orderDetails.value.shipping_address.address1 = shipping_address.address1;
              orderDetails.value.shipping_address.address2 = shipping_address.address2;
              orderDetails.value.shipping_address.city = shipping_address.city;
              orderDetails.value.shipping_address.state = shipping_address.state;
              orderDetails.value.shipping_address.state_code = shipping_address.state_code;
              orderDetails.value.shipping_address.country = shipping_address.country;
              orderDetails.value.shipping_address.country_code = shipping_address.country_code;
              orderDetails.value.shipping_address.postcode = shipping_address.postcode;
              orderDetails.value.shipping_address.phone = shipping_address.phone;
              document.getElementById("suggested_address_close_button").click();
              notificationFire(message, generalConstants.NOTIFY_SUCCESS);
            }
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push({ name: "orders" });
            } else {
              notificationFire(message, "error");
            }
          } 
        })
        .finally(() => {
          saveLoader.value = false;
        });
      }
    };

    //save and generate shipping label
    const generateShipment = async () => {
      if (!formRef.value) return;
      if ((typeof fromAddress.value.trading_partner_address_id === "undefined")) {
        notificationFire(ordersConstants.ADD_SITE_ADDRESS_ERROR_MESSAGE, "error");
        return;
      } else {
        await formRef.value.validate((valid) => {
          if (orderShipmentType.value == 2) {
            var checkboxes = document.querySelectorAll("input[name='product_checkbox']");
            var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
            if (!checkedOne) {
              isProductCheck.value = true;
              notificationFire(ordersConstants.PLEASE_SELECT_AT_LEAST_ONE_PRODUCT, "error");
              loading.value = disabled.value = generateShipmentLoading.value = false;
              return;
            }
          }
          if (valid) {
            if (addressValidationClass.value == "danger") {
              notificationFire(ordersConstants.INVALID_FROM_ADDRESS_ERROR_MESSAGE, "error");
              return;
            }
            loading.value = disabled.value = generateShipmentLoading.value = true;
            let updatedformData = JSON.parse(JSON.stringify(formData));
            if (orderShipmentType.value == 1) {
              //order shipment
              updatedformData.packageConfig = [updatedformData.orderPackages];
            } else {
              //order product shipment
              let filterProductsPackage = {};
              Object.keys(updatedformData.productPackages).forEach((key) => {
                let isChecked = document.getElementById("product_" + key).checked;
                if (updatedformData.productPackages[key].length > 0 && updatedformData.productPackages[key] != null && isChecked) {
                  filterProductsPackage[key] = updatedformData.productPackages[key];
                }
              });
              updatedformData.packageConfig = filterProductsPackage;
              // let filterProductsPackage = {};
              // Object.keys(updatedformData.productPackages).forEach((key) => {
              //   if (updatedformData.productPackages[key].length > 0 && updatedformData.productPackages[key] != null) {
              //     filterProductsPackage[key] = updatedformData.productPackages[key];
              //   }
              // });
              // updatedformData.packageConfig = filterProductsPackage;
            }
            delete updatedformData.orderPackages;
            delete updatedformData.productPackages;
            
            let requestUrl = shipmentRegenerateId.value > 0 ? "order-shipment/" + shipmentRegenerateId.value : "order-shipment";
            ApiService.post(requestUrl, {
              _method: shipmentRegenerateId.value > 0 ? "PUT" : "POST", 
              fromAddress: fromAddress.value,
              toAddress: orderDetails.value.shipping_address,
              order_id: orderId.value,
              type: orderShipmentType.value,
              shipping_method: updatedformData.shipping_method,
              shipping_type: updatedformData.shipping_type,
              tracking_no: updatedformData.tracking_no,
              packages: updatedformData.packageConfig
            })
              .then(async (response) => {
                if (response.data) {
                  let status = response.status;
                  let message = response.data.message;
                  if (status == 200 || status == 201) {
                    shippingRates.shippingMethodsRates = shippingRates.shippingMethodsName = [];
                    await getShipmentDetails();
                    loading.value = disabled.value = generateShipmentLoading.value = false;
                    notificationFire(message, "success");
                    let path = process.env.VUE_APP_API_URL;
                    path = path.replace("/api/v1", "");
                    window.open(path + response.data.data.data.file, "_blank");
                    if (!formRef.value) return;
                    formRef.value.resetFields();
                  }
                }
              })
              .catch(async (error) => {
                await getShipmentDetails();
                loading.value = disabled.value = generateShipmentLoading.value = false;
                let response = error.response;
                if (response) {
                  let status = response.status;
                  let message = response.data.message;
                  if (status == 404) {
                    router.push({ name: "orders" });
                  } else {
                    notificationFire(message, "error");
                  }
                } 
              })
              .finally(async () => {
                await checkGenerateShippingLabel();
              });
          }
        });
      }
    };

    const reGenerateShippingLabel = async (shipmentId) => {
      if (shipmentId) {
        //set update shipment id
        shipmentRegenerateId.value = shipmentId;
        let orderShipmentRow = [];
        orderShipmentRow = orderShipmentData.value.find((val) =>
          val.order_shipment_id == shipmentId
        );
        //set common data
        formData.shipping_method = orderShipmentRow.shipping_method_id;
        getShippingTypeData(orderShipmentRow.shipping_method_id);
        formData.shipping_type = orderShipmentRow.shipping_type_id;
        formData.tracking_no = "";
        formData.orderPackages = [];
        formData.productPackages = [];
        //set order packages
        if (orderShipmentRow.type == 1) {
          isDisabledOrderShipment.value = false;
          orderShipmentType.value = 1;
          orderShipmentRow.order_shipment_packages.forEach(shipmentPackage => {
            let tempPackage = {
              weight_unit: shipmentPackage.weight_class,
              weight: shipmentPackage.weight,
              quantity: shipmentPackage.quantity,
              dimension_unit: shipmentPackage.length_class,
              length: shipmentPackage.package_length,
              height: shipmentPackage.package_height,
              width: shipmentPackage.package_width
            };
            formData.orderPackages.push(tempPackage);
          });
        } else {
          //set order product packages
          isDisabledOrderShipment.value = true;
          orderShipmentType.value = 2;
          let orderProductsByShipment = orderShipmentRow.order_shipment_packages.reduce(function (obj, item) {
            obj[item.order_product_id] = obj[item.order_product_id] || [];
            obj[item.order_product_id].push(item);
            return obj;
          }, {});
          let productPackageObj = {};
          for (var key in orderProductsByShipment) {
            let shipmentObj = [];
            orderProductsByShipment[key].forEach(shipmentPackage => {
              let tempPackage = {
                weight_unit: shipmentPackage.weight_class,
                weight: shipmentPackage.box_weight,
                quantity: shipmentPackage.quantity,
                dimension_unit: shipmentPackage.length_class,
                length: shipmentPackage.package_length,
                height: shipmentPackage.package_height,
                width: shipmentPackage.package_width
              };
              shipmentObj.push(tempPackage);
              productPackageObj[key] = shipmentObj;
            });
          }
          formData.productPackages = productPackageObj;
        }
      }
    };

    //Get shipping type data
    const getShippingTypeData = async (shippingMethodId) => {
      formData.shipping_type = "";
      if (shippingMethodId) {
        shippingTypeData.value = shippingMethodTypes[shippingMethodId];
      }
      //check is shipping method is offline then display tracking number
      isDisplayTrackingNumber.value = shippingMethods.some(value =>
        value.shipping_method_id == shippingMethodId && value.shipping_mode == 0
      );
    };

    const checkGenerateShippingLabel = async () => {
      //check only one order shipment and type is order and tracking id is available
      if (orderShipmentData.value.length > 0) {
        //remove order product that is already shipped
        if( orderShipmentData.value.length == 1 && orderShipmentData.value[0].tracking_no == null){
          isGenerateShippingLabel.value = true;
        }
        let totalShippedProduct = 0;
        orderShipmentData.value.forEach((shipment) => {          
          if (shipment.type == 1 && shipment.tracking_no != null) {
            isGenerateShippingLabel.value = false;
            isRegenerateShipmentLabel.value = false;
          }
          if (shipment.type == 2 && shipment.tracking_no != null) {
            isDisabledOrderShipment.value = true;
            isRegenerateShipmentLabel.value = false;
            let orderProductData = shipment.order_shipment_packages.filter((packages) => packages.tracking_no != null);
            let orderProductsByShipment = orderProductData.reduce(function (obj, item) {
              obj[item.order_product_id] = obj[item.order_product_id] || [];
              obj[item.order_product_id].push(item.tracking_no);
              return obj;
            }, {});
            for (var key in orderProductsByShipment) {
              delete formData.productPackages[key];
              const assignOrderPro = {};
              Object.keys(formData.productPackages).forEach(key => {
                assignOrderPro[key] = formData.productPackages[key];
              });
              totalShippedProduct++;
            }
          }
        });
        if (isDisabledOrderShipment.value) {
          orderShipmentType.value = 2;
          isGenerateShippingLabel.value = (totalShippedProduct == tableData.value.length) ? false : true;
          isRegenerateShipmentLabel.value = (totalShippedProduct == tableData.value.length) ? false : true;
        }
      } else {
        isGenerateShippingLabel.value = true;
        isDisabledOrderShipment.value = false;
      }
    };

    //Delete the particular order shipment
    const deleteOrderShipment = async (shipmentId) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: "warning",
          center: true,
        }
      ).then(() => {
        loading.value = true;
        ApiService.delete("order-shipment/" + shipmentId)
          .then(async (response) => {
            let status = response.status;
            let message = response.data.message;
            if (status == 200) {
              let index = orderShipmentData.value.findIndex(
                (item) => item.id == shipmentId
              );
              orderShipmentData.value.splice(index, 1);
              if (orderShipmentType.value == 1) {
                formData.orderPackages = [];
                addOrderPackage();
              } else {
                formData.productPackages = [];
                setOrderProductPackage();
              }
              formData.shipping_method = [];
              formData.shipping_type = "";
              await getShipmentDetails();
              await checkGenerateShippingLabel();
              isRegenerateShipmentLabel.value = orderShipmentData.value.every(shipment => { if (shipment.tracking_no == null) { return true; } });
              notificationFire(message, "success");
            }
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              if (status == 404) {
                router.push({ name: "orders" });
              } else {
                notificationFire(message, "error");
              }
            }
          })
          .finally(() => {
            loading.value = false;
          });
      });
    };

    const setOrderProductPackage = async () => {
      let blankPackageData = {};
      let blankPackageobject = {
        weight_unit: "",
        weight: "",
        quantity: "",
        length_unit: "",
        length: "",
        height: "",
        width: "",
        validation_check: false
      };
      tableData.value.forEach((value) => {
        blankPackageData[value.product_id] = [blankPackageobject];
      });
      formData.productPackages = blankPackageData;
      tableData.value.forEach((value) => {
        formData.productPackages[value.product_id].splice(0, 1);
        addProductPackage(value.product_id);
      });
    };

    //Validate address
    const validateAddress = (address) => {
      addressValidationMessage.value = ordersConstants.ADDRESS_VALIDATION_IN_PROGRESS;
      addressValidationClass.value = "primary";
      ApiService.post("validate-address",{
            // address: address
          name: address.name,
          company: address.company,
          address_line_1: address.address1,
          address_line_2: address.address2,
          city: address.city,
          state: address.state,
          state_code: address.state_code,
          country: address.country,
          country_code: address.country_code,
          postal_code: address.postcode,
          phone: address.phone_number
        })
      .then((response) => {
        if (response.data) {
          // let status = response.data.status;
          let data = response.data.data;
          let message = response.data.msg;
          let resp = response.data.resp;
          if (resp == "valid") {
            addressValidationMessage.value = message;
            addressValidationClass.value = "success"; 
          } else if (resp == "invalid") {
            addressValidationMessage.value = message;
            addressValidationClass.value = "danger"; 
            addressValidationResponse.value = data;
          } else if (resp == "suggested") {
            addressValidationMessage.value = message;
            addressValidationClass.value = "warning";
            let suggestedData = [];
            data.forEach((value) => {
              suggestedData.push({
                name: value.name,
                // company: value.consigneeName,
                address1: value.addressLine,
                address2: value.addressLine2,
                city: value.politicalDivision2,
                state: value.state,
                state_code: value.politicalDivision1,
                country: value.country,
                country_code: value.countryCode,
                postal_code: value.postcodeExtendedLow + "-" + value.postcodePrimaryLow,
              });
            });
            suggestedAddresses.value = suggestedData;
          } else {
            addressValidationMessage.value = message;
            addressValidationClass.value = "danger"; 
          }
        }
      })
      .catch((error) => {
        let message = "";
        if (typeof error.response !== "undefined") {
            if (error.response.status == 404 || error.response.status == 500) {
                message = error.response.status + " " + error.response.statusText;
            } else {
                if (typeof error.response.data.message == "object") {
                    message = error.response.data.message.join(", ");
                } else {
                    message = error.response.data.message;
                }
            }
        } else if (typeof error.message !== "undefined") {
            message = error.message;
        } else {
            message = ordersConstants.SOMETHING_WENT_WRONG;
        }
        addressValidationMessage.value = ordersConstants.INVALID_ADDRESS;
        addressValidationClass.value = "danger"; 
        addressValidationResponse.value = message;
      });
    };

    const checkOrderProductIsChecked = (value, productId) => {
      if (value) {
        isProductCheck.value = false;
        for (let i = 0; i < formData.productPackages[productId].length; i++) {
          formData.productPackages[productId][i].validation_check = true;
        }
      } else {
        var checkboxes = document.querySelectorAll("input[name='product_checkbox']");
        var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
        if (!checkedOne) {
          isProductCheck.value = true;
        }
        for (let i = 0; i < formData.productPackages[productId].length; i++) {
          formData.productPackages[productId][i].validation_check = false;
        }
      }
    };

    //Validation for quantity field
    const checkProductQuantity = (rule, value, callback) => {
      let fieldArr = rule.field.split(".");
      let productId = fieldArr[1];
      let productWiseCount = 0;
      formData.productPackages[productId].forEach(element => {
        productWiseCount += parseInt(element.quantity);
      });
      let productItem = tableData.value.filter((val) =>
        val.product_id == productId
      );
      let productTotalQuantity = productItem[0].product_quantity;
      let isChecked = document.getElementById("product_" + productId).checked;
      if (isChecked && value === "") {
        callback(new Error(ordersConstants.QUANTITY_IS_REQUIRED));
      } else if (!isChecked && value != "") {
        callback();
      } else if (productWiseCount > productTotalQuantity) {
        callback(new Error(ordersConstants.QUANTITY_LIMIT_IS_EXCEED));
      } else {
        callback();
      }
    };

    const updateToAddress = () => {
      {{returnAddresses.value}}
      if (returnAddresses.value != null) {
        fromAddress.value = returnAddresses.value[selectedAddress.value];
      }
      document.getElementById("select_address_close_button").click();
    };

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "back") {
        router.push("/" + fromRoutePath);
      }
    });

    //Reinitialize menu component
    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" :fromRouteName, 'path': fromRoutePath}, { name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("readOrderHistory") || role == "Super Admin")
        permission.isReadOrderHistoryAllowed = true;
      if (per.includes("downloadShipmentLabel") || role == "Super Admin")
        permission.isDownloadShipmentLabelAllowed = true;
      orderId.value = parseInt(route.params.id);
      await getMasterPackage();
      await getShippingMethodsAndType();
      await getOrderDetails();
      await getShipmentDetails();
      getReturnAddresses();
      setOrderProductPackage();
      checkGenerateShippingLabel();
      validateAddress(orderDetails.value.shipping_address);
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" : fromRouteName, 'path': fromRoutePath}, { name: formatText(route.name), active: true }]);
      setCurrentPageActionButtons([{"name" :generalConstants.BACK, 'action': 'back','class': 'btn-primary','isLoader': false,'iconClass':"bi bi-arrow-left fs-4" }]);
    });

    return {
      orderId,
      orderDetails,
      formatPrice,
      formatDate,
      formatDateTime,
      fullscreenLoading,
      tableData,
      tableRowClassName,
      formatText,
      generalConstants,
      ordersConstants,
      tableHeader,
      noDataFound,
      loading,
      manageOrderProductstatus,
      orderProductId,
      updateOrderProductItems,
      openDetailPage,
      orderHistoryTableHeader,
      permission,
      statusId,
      updateProps,
      downloadFiles,
      prepareshipmentData,
      manageShipmentLabel,
      orderHistoryTableData,
      constants,
      stopScrolling,
      closeImage,
      openPendingOrderDetailPage,
      orderShipmentData,
      showHideProducts,
      downloadShipmentLabel,
      isGenerateShippingLabel,
      returnAddresses,
      fromAddress,
      formData,
      shipmentType,
      orderShipmentType,
      shippingMethods,
      getShippingRates,
      formRef,
      addressValidationMessage,
      addressValidationClass,
      addressValidationResponse,
      rules,
      isDisabledOrderShipment,
      isDisplayTrackingNumber,
      shippingRates,
      selectedShippingRate,
      getShipmentRatesDisabled,
      getShipmentRatesLoading,
      masterPackages,
      dimensionUnit,
      weightUnit,
      getMasterPackageRowDataForOrder,
      getMasterPackageRowDataForOrderProduct,
      masterPackageData,
      addProductPackage,
      deletePackageConfigurationOfOrderProduct,
      deletePackageConfigurationOfOrder,
      addOrderPackage,
      showModal,
      modalFormData,
      modalFormRules,
      submitForm,
      saveLoader,
      modalFormRef,
      generateShipmentLoading,
      disabled,
      generateShipment,
      isRegenerateShipmentLabel,
      shipmentRegenerateId,
      deleteOrderShipment,
      reGenerateShippingLabel,
      shippingTypeData,
      checkOrderProductIsChecked,
      isProductCheck,
      checkProductQuantity,
      suggestedAddresses,
      selectedSuggestedAddress,
      selectedAddress,
      updateToAddressBySuggestedAddress,
      updateToAddress
    };
  },
};
</script>

<style>
.el-table .success-row {
  color: #4E5155;
  background-color: #02bc7714;
}
.order-detail-card .card-header{
  padding: 0 2.25rem !important;
}
</style>